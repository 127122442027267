// TODO use bs base variables if possible
@selectlist-horizontal-padding: 12px;
@selectlist-caret-width: 4px;

.fuelux {

	.selectlist {

		&.disabled {
			cursor: not-allowed;
		}

		.selected-label {
			overflow: hidden;	// helps with text flow on small screens
			min-width: 2em;
		}

		.dropdown-menu {
			min-width: inherit;
			overflow-y: auto;
		}
		.btn.dropdown-toggle {
			text-align: left;
			padding-right: unit((2 * @selectlist-horizontal-padding + @selectlist-caret-width), px);

			.caret {
				#fuelux > .vertical-align();
				right: @selectlist-horizontal-padding;

			}
		}

	}

	.button-sizer, .selectlist-sizer{
		display: inline-block;
		position: absolute;
		visibility: hidden;
		top: 0;
		float: left;
		.selectlist {
			display: block !important;
		}
		.dropdown-menu {
			display: block;
			min-width: inherit;
		}
		.selected-label {
			width: auto !important;
		}
	}

}