.fuelux {

	 .combobox {
		&.disabled {
			.input-group-btn {
				cursor: not-allowed;
			}
		}
		input {
			&::-ms-clear {
				display:none;
			}
		}
	}

}
