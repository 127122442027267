.fuelux {

	// Font Family
	// -------------------------
	.serif {
		font-family: @serifFontFamily;
	}
	.monospace {
		font-family: @monoFontFamily;
	}

	.text-sm {
		font-size: @fontSizeSmall;
	}
	.text-lg {
		font-size: @fontSizeLarge;
	}

	// Padding
	// -------------------------
	.padding {
		&-xs {
			padding: @padding-xs;
			&-horizontal {
				padding: 0 @padding-xs;
			}
			&-vertical {
				padding: @padding-xs 0;
			}
			&-top {
				padding-top: @padding-xs;
			}
			&-bottom {
				padding-bottom: @padding-xs;
			}
			&-left {
				padding-left: @padding-xs;
			}
			&-right {
				padding-right: @padding-xs;
			}
		}
		&-sm {
			padding: @padding-sm;
			&-horizontal {
				padding: 0 @padding-sm;
			}
			&-vertical {
				padding: @padding-sm 0;
			}
			&-top {
				padding-top: @padding-sm;
			}
			&-bottom {
				padding-bottom: @padding-sm;
			}
			&-left {
				padding-left: @padding-sm;
			}
			&-right {
				padding-right: @padding-sm;
			}
		}
		&-md {
			padding: @padding-md;
			&-horizontal {
				padding: 0 @padding-md;
			}
			&-vertical {
				padding: @padding-md 0;
			}
			&-top {
				padding-top: @padding-md;
			}
			&-bottom {
				padding-bottom: @padding-md;
			}
			&-left {
				padding-left: @padding-md;
			}
			&-right {
				padding-right: @padding-md;
			}
		}
		&-lg {
			padding: @padding-lg;
			&-horizontal {
				padding: 0 @padding-lg;
			}
			&-vertical {
				padding: @padding-lg 0;
			}
			&-top {
				padding-top: @padding-lg;
			}
			&-bottom {
				padding-bottom: @padding-lg;
			}
			&-left {
				padding-left: @padding-lg;
			}
			&-right {
				padding-right: @padding-lg;
			}
		}
	}

	// Margin
	// -------------------------
	.margin {
		&-xs {
			margin: @margin-xs;
			
			&-horizontal {
				margin: 0 @margin-xs;
			}
			&-vertical {
				margin: @margin-xs 0;
			}
			&-top {
				margin-top: @margin-xs;
			}
			&-bottom {
				margin-bottom: @margin-xs;
			}
			&-left {
				margin-left: @margin-xs;
			}
			&-right {
				margin-right: @margin-xs;
			}
		}
		&-sm {
			margin: @margin-sm;
			&-horizontal {
				margin: 0 @margin-sm;
			}
			&-vertical {
				margin: @margin-sm 0;
			}
			&-top {
				margin-top: @margin-sm;
			}
			&-bottom {
				margin-bottom: @margin-sm;
			}
			&-left {
				margin-left: @margin-sm;
			}
			&-right {
				margin-right: @margin-sm;
			}
		}
		&-md {
			margin: @margin-md;
			&-horizontal {
				margin: 0 @margin-md;
			}
			&-vertical {
				margin: @margin-md 0;
			}
			&-top {
				margin-top: @margin-md;
			}
			&-bottom {
				margin-bottom: @margin-md;
			}
			&-left {
				margin-left: @margin-md;
			}
			&-right {
				margin-right: @margin-md;
			}
		}
		&-lg {
			margin: @margin-lg;
			&-horizontal {
				margin: 0 @margin-lg;
			}
			&-vertical {
				margin: @margin-lg 0;
			}
			&-top {
				margin-top: @margin-lg;
			}
			&-bottom {
				margin-bottom: @margin-lg;
			}
			&-left {
				margin-left: @margin-lg;
			}
			&-right {
				margin-right: @margin-lg;
			}
		}
	}

	// Helpers
	// -------------------------
	.data-label {
		#fuelux > .data-label();
	}
	.data-value {
		#fuelux > .data-value();
	}
	.section-heading {
		#fuelux > .section-heading();
	}

}